<template>
  <div class="swap">
    <headbar :title="$t('移除流动性')" bgColor="#080535"></headbar>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <img src="@/assets/img/logo.png" class="header-icon" />
          <div class="title">{{ $t('移除流动性') }}</div>
        </div>
        <!-- <div class="link" @click="tolink('ChongzhiRecord?lx=4&title=映射记录')">{{ $t('映射记录') }}</div> -->
      </div>
      <div class="input-wrap q-mt-md">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="cell text-white" style="margin-top: 15px;">
        <div>{{ $t('可用') }}Lp：{{ balance.lp }}</div>
        <div class="all-text" @click="num = balance.lp">{{ $t('全部') }}</div>
      </div>
      <div class="row q-mt-lg">
        <div class="btn col" v-if="userAllowance.lp < parseFloat(num) || num==''" @click="approve">授权</div>
        <div class="btn col" v-if="userAllowance.lp >= parseFloat(num)" @click="remove">移除</div>
      </div>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      userAllowance: ref({
        usdt:0,
        lbd:0,
        lp:0
      }),
      balance: ref({
        bnb:0,
        usdt:0,
        lbd:0,
        lp:0
      }),

      contractInfoUSDT: ref(null),
      contractInfoLP: ref(null),
      contractInfoApproveContract: ref(null),
      contractInfoPayContract: ref(null),
      contractInfoSwapRouterV3: ref(null),

      usdtWeb3Helper: ref({}),
      lpWeb3Helper: ref({}),
      payContractWeb3Helper: ref({}),
      swapRouterV2Web3Helper: ref({}),
      swapRouterV3Web3Helper: ref({}),

      showTxid: ref(false),
      txid: ref(""),
      inAddress3: ref(""),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getInfo()
  },
  methods: {
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data

          _this.contractInfoUSDT = data.contractList.find(item => item.id == 1);
          _this.usdtWeb3Helper = new Web3Helper(_this.contractInfoUSDT.abi, _this.contractInfoUSDT.address);

          _this.contractInfoLP = data.contractList.find(item => item.id == 15);
          _this.lpWeb3Helper = new Web3Helper(_this.contractInfoLP.abi, _this.contractInfoLP.address);

          _this.contractInfoApproveContract = data.contractList.find(item => item.id == 102);

          _this.contractInfoPayContract = data.contractList.find(item => item.id == 103);
          _this.payContractWeb3Helper = new Web3Helper(_this.contractInfoPayContract.abi, _this.contractInfoPayContract.address);

          var swapRouterV2ContractInfo = data.contractList.find(item => item.id == 100)
          _this.swapRouterV2Web3Helper = new Web3Helper(swapRouterV2ContractInfo.abi, swapRouterV2ContractInfo.address);

          _this.inAddress3 = data.inAddress3;

          _this.balanceOf(_this.us.address);
          _this.allowance(_this.us.address)
        }
      )
    },
    allowance(address) {
      let _this = this;
      _this.lpWeb3Helper.contractAllowance(address, _this.contractInfoApproveContract.address).then((wei) => {
        _this.userAllowance.lp = _this.lpWeb3Helper.fromWei(wei, _this.contractInfoLP.decimals);
      });
    },
    balanceOf(address) {
      let _this = this;
      _this.lpWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance.lp = _this.lpWeb3Helper.fromWei(wei, _this.contractInfoLP.decimals);
      });
    },
    approve() {
      let _this = this

      let web3Helper = _this.lpWeb3Helper;

      let payWei = web3Helper.web3.utils.toBN('2').pow(web3Helper.web3.utils.toBN('256')).sub(web3Helper.web3.utils.toBN('1'));

      _this.$q.loading.show({
        message: _this.$i18n.t("正在交易中...")
      })

      let approveAddress;
      approveAddress = _this.contractInfoApproveContract.address;

      web3Helper.contractApprove(_this.us.address, approveAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.allowance(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    },
    remove() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ['确定要移除吗?'],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let payWei = _this.lpWeb3Helper.toWei(_this.num, _this.contractInfoLP.decimals);

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        _this.payContractWeb3Helper.callRemoveLiquidity(_this.us.address, payWei.toString(), 0, 0, _this.inAddress3).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.balanceOf(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                title: err.code,
                messages: [err.message],
                persistent: true,
              }
            });
          }
        });
      })
    }
  }
}
</script>

<style scoped>
.swap {
  padding: 15px 15px 70px;
  color: #fff;
}

.card {
  padding: 15px;
  border-radius: 20px;
  background-color: #2f1d51;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
}

.link {
  color: #e96dff;
  text-decoration: underline;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.cell {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.all-text {
  color: #e96dff;
}

.btn {
  margin: 0 5px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}
</style>